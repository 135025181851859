import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Badge,
  BadgeText,
  Button,
  ButtonText,
  Center,
  VStack,
  Heading,
  BadgeIcon,
  GlobeIcon,
  Alert,
  AlertIcon,
  InfoIcon,
  AlertText,
  useToast,
  Toast,
  ToastTitle,
  AvatarFallbackText,
  AvatarImage,
  Avatar,
  HStack,
  Box,
} from '@gluestack-ui/themed';
import ViewQRCodeModal from './ViewQRCodeModal';
import { AuthContext } from '../../AuthProvider';

export default function DepositInfo(params) {
  const [deposit, setDeposit] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const { user, login, logout } = useContext(AuthContext);

  const toast = useToast();

  function copyToClipboard(text) {
    (async () => {
      try {
        await navigator.clipboard.writeText(text);
        /* ✅ Copied successfully */

        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="info" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Address Copied To Clipboard</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      } catch (e) {
        /* ❌ Failed to copy (insufficient permissions) */
        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="error" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Unable To Copy Address</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      }
    })();
  }
  useEffect(() => {
    (async () => {
      ///3fa9ff23-ac08-44c0-91e0-4f58f37b8e96

      setDeposit(params.deposit);

      setLoading(false);
    })();
  }, [params]);

  return (
    <>
      {deposit !== null && (
        <Center>
          <Heading>Deposit Info</Heading>
          {deposit.status === 'confirmed' && (
            <>
              <Heading>Dogecoin payment confirmed</Heading>

              <Heading>Deposit Amount:{deposit.balance} DOGE</Heading>

              {user !== false &&
                user.winnings_management === 'letsbetmx' &&
                deposit.user_uid === user.uid && (
                  <Alert mx="$2.5" action="info" variant="solid">
                    <AlertIcon as={InfoIcon} mr="$3" />
                    <AlertText>
                      Dogecoin winnings will be sent to your LetsBetMX account.
                      You can configure Dogecoin address in Profile
                    </AlertText>
                  </Alert>
                )}
              {user !== false &&
                user.winnings_management !== 'letsbetmx' &&
                deposit.user_uid === user.uid && (
                  <Alert mx="$2.5" action="info" variant="solid">
                    <AlertIcon as={InfoIcon} mr="$3" />
                    <AlertText>
                      Dogecoin winnings will be sent to the Dogecoin address
                      configured in your Profile
                    </AlertText>
                  </Alert>
                )}
            </>
          )}

          {deposit.status === 'expired' && <Heading>Deposit Expired</Heading>}

          {deposit.status === 'to_be_confirmed' && (
            <>
              <HStack space={'lg'}>
                <Avatar size={'md'}>
                  <AvatarFallbackText></AvatarFallbackText>
                  <AvatarImage
                    source={{
                      uri: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/icon/doge.svg',
                    }}
                  />
                </Avatar>
                <Heading>{deposit.amount_to_be_deposited} DOGE</Heading>
              </HStack>

              <Heading>Deposit Dogecoins To:</Heading>
              <Badge
                size="xl"
                variant="solid"
                borderRadius="$none"
                action="info"
              >
                <BadgeText>{deposit.deposit_address}</BadgeText>
                <BadgeIcon as={GlobeIcon} ml="$2" />
              </Badge>
              <br />
              <HStack space={'md'}>
                <Button
                  onPress={() => copyToClipboard(deposit.deposit_address)}
                >
                  <ButtonText>Copy Address</ButtonText>
                </Button>

                <ViewQRCodeModal address={deposit.deposit_address} />
              </HStack>

              <Heading>
                Deposit Expires At{' '}
                {new Date(deposit.expire_time.seconds * 1000).toString()}
              </Heading>
            </>
          )}
        </Center>
      )}
    </>
  );
}
