import React, { useContext, useEffect, useState } from 'react';
import {
  RefreshControl,
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
} from 'react-native';
import {
  and,
  collection,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  where,
} from 'firebase/firestore';

import firebaseUtils from '../../Firebase';
import GamematchCard from './GamematchCard';
import FilterChips from './SportFilterChips';
import { Heading } from '@gluestack-ui/themed';

import BetFeed from './BetFeed';
import { FlatList, View } from 'react-native-web';
import Loading from './Loading';
import { AuthContext } from '../../AuthProvider';

export default function GamematchList() {
  const { user, login, logout } = useContext(AuthContext);

  const [sport, setSport] = useState('all');

  const [gamematches, setGamematches] = React.useState([]);
  const [openBets, setOpenBets] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [refreshing, setRefreshing] = React.useState(false);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 2000);
  }, []);

  useEffect(() => {
    (async () => {
      const new_gamematches = [];

      ///where("status.long", "==", "Not Started"),
      let q = null;
      let betQuery = null;
      const existingBetsRef = collection(firebaseUtils('db'), 'bets');
      const gamesmatchRef = collection(firebaseUtils('db'), 'gamematch');
      console.log('heyyy new sport' + sport);

      if (sport === 'all') {
        q = query(
          gamesmatchRef,
          and(
            or(
              where('status.short', '==', 'NS'),
              where('game.status.short', '==', 'NS'),
              where('fixture.status.short', '==', 'NS'),
            ),
          ),
          limit(20),
        );
        betQuery = query(
          existingBetsRef,
          where('status', '==', 'open_for_counter_bets'),
          limit(20),
        );
      } else {
        if (sport === 'baseball' || sport === 'basketball') {
          q = query(
            gamesmatchRef,
            and(
              where('status.short', '==', 'NS'),

              where('sport', '==', sport),
            ),
            orderBy('timestamp', 'asc'),
            limit(20),
          );
          betQuery = query(
            existingBetsRef,
            where('status', '==', 'open_for_counter_bets'),
            limit(20),
          );
        }

        if (sport === 'soccer') {
          q = query(
            gamesmatchRef,
            and(
              where('fixture.status.short', '==', 'NS'),

              where('sport', '==', sport),
            ),
            orderBy('fixture.timestamp', 'asc'),
            limit(20),
          );
          betQuery = query(
            existingBetsRef,
            where('status', '==', 'open_for_counter_bets'),
            limit(20),
          );
        }

        if (sport === 'football') {
          q = query(
            gamesmatchRef,
            and(
              where('game.status.short', '==', 'NS'),
              where('sport', '==', sport),
            ),
            orderBy('game.date.timestamp', 'asc'),
            limit(20),
          );

          betQuery = query(
            existingBetsRef,
            where('status', '==', 'open_for_counter_bets'),
            limit(20),
          );
        }
      }

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc1) => {
        const gamematch = doc1.data();
        let start_time = null;
        if (
          gamematch.sport === 'baseball' ||
          gamematch.sport === 'basketball'
        ) {
          start_time = new Date(gamematch.timestamp * 1000);
        }
        if (gamematch.sport === 'soccer') {
          start_time = new Date(gamematch.fixture.timestamp * 1000);
        }
        if (gamematch.sport === 'football') {
          start_time = new Date(gamematch.game.date.timestamp * 1000);
        }
        const current_time = new Date();
        if (current_time < start_time) {
          new_gamematches.push(gamematch);
          // console.log(doc1.data());
        }
      });

      setGamematches(new_gamematches);

      const new_open_bets = [];

      const betQuerySnapshot = await getDocs(betQuery);
      betQuerySnapshot.forEach((doc1) => {
        new_open_bets.push(doc1.data());
      });

      setOpenBets(new_open_bets);
      setLoading(false);
    })();
  }, [sport, user]);

  return (
    <SafeAreaView style={styles.container}>
      <FilterChips onChangeSport={setSport} />
      <Heading>Open Bets</Heading>
      <View style={{ height: '47%', width: '95%' }}>
        {openBets.length > 0 && !loading && (
          <FlatList
            data={openBets}
            renderItem={({ x, index }) => {
              return (
                <BetFeed
                  key={openBets[index]['uuid']}
                  bet={openBets[index]}
                  showbuttons={true}
                />
              );
            }}
            keyExtractor={(x, index) => openBets[index]['uuid']}
            horizontal={true}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
          />
        )}
        {loading && <Loading />}
      </View>
      <Heading>Games Today</Heading>
      <View style={{ height: '37%', width: '95%' }}>
        {gamematches.length > 0 && !loading && (
          <FlatList
            data={gamematches}
            renderItem={({ x, index }) => {
              return (
                <GamematchCard
                  key={gamematches[index]['firedb_id']}
                  gamematch={gamematches[index]}
                />
              );
            }}
            keyExtractor={(x, index) => gamematches[index]['firedb_id']}
            horizontal={true}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
          />
        )}
        {loading && <Loading />}
      </View>
    </SafeAreaView>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f1f2f5',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
  },
  scrollView: {
    // backgroundColor: 'pink',
    // marginHorizontal: 0,
    width: '100%',
  },
  buttonContainer: {
    backgroundColor: '#4630eb',
    borderRadius: 4,
    padding: 12,
    marginVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    color: '#ffffff',
    fontSize: 16,
  },
});
