import React, { useContext, useEffect } from 'react';
import { TouchableOpacity } from 'react-native';
import bs58check from 'bs58check';
import {
  Center,
  Heading,
  Box,
  Button,
  Avatar,
  AvatarFallbackText,
  Badge,
  BadgeIcon,
  BadgeText,
  ButtonText,
  VStack,
  HStack,
  Divider,
  Text,
  Actionsheet,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetSectionHeaderText,
  Alert,
  AlertIcon,
  InfoIcon,
  AlertText,
  ActionsheetVirtualizedList,
  ActionsheetItemText,
  ActionsheetItem,
} from '@gluestack-ui/themed';
import SetUpPersonalAddressModal from './shared/SetUpPersonalAddressModal';
import UserNotLoggedIn from './shared/UserNotLoggedIn';
import Loading from './shared/Loading';
import { AuthContext } from '../AuthProvider';
import {
  and,
  collection,
  getDocs,
  limit,
  or,
  query,
  where,
} from 'firebase/firestore';
import firebaseUtils from '../Firebase';
export default function Settings() {
  const { user, login, logout, updateUser } = useContext(AuthContext);

  const [balance, setBalance] = React.useState(0.0);
  const [sports, setSports] = React.useState({
    basketball: [],
    baseball: [],
    soccer: [],
    football: [],
  });
  const [selectedSport, setSelectedSport] = React.useState({
    teams: [],
    sport: '',
  });
  const [loading, setLoading] = React.useState(true);
  const [showFavoritesSheet, setShowFavoritesSheet] = React.useState(false);
  const [updateLoading, setUpdateLoading] = React.useState(false);

  useEffect(() => {
    (async () => {
      const gamesmatchRef = collection(firebaseUtils('db'), 'teams');

      const q = query(gamesmatchRef, limit(100));
      const new_teams = [];
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc1) => {
        const gamematch = doc1.data();
        new_teams.push(gamematch);
      });

      const sports = {
        baseball: new_teams.filter((t) => t.sport === 'baseball'),
        basketball: new_teams.filter((t) => t.sport === 'basketball'),
        soccer: new_teams.filter((t) => t.sport === 'soccer'),
        football: new_teams.filter((t) => t.sport === 'football'),
      };
      console.log('sports', sports);
      setSports(sports);
      setLoading(false);
    })();
  }, [user]);

  const handleSportOnClick = (sportSelected) => {
    setShowFavoritesSheet(true);
    setSelectedSport({ sport: sportSelected, teams: sports[sportSelected] });
  };

  const setFavoriteTeam = (team_id) => {
    setUpdateLoading(true);
    if (user.favorites === undefined) {
      user.favorites = [];
      user.favorites.push(team_id);
    } else if (user.favorites.includes(team_id)) {
      user.favorites = user.favorites.filter((t) => t !== team_id);
    } else {
      user.favorites.push(team_id);
    }

    updateUser(user, setUpdateLoading);
  };
  const handleClose = () => setShowFavoritesSheet(!showFavoritesSheet);
  const data = React.useMemo(
    () =>
      Array(50)
        .fill(0)
        .map((_, index) => 'Item' + index),
    [],
  );
  const getItem = (_data, index) => _data[index];
  const getItemCount = (_data) => _data.length;
  const Item = React.useCallback(
    ({ firedb_id, name, logo }) => (
      <ActionsheetItem
        style={{
          backgroundColor:
            user.favorites !== undefined && user.favorites.includes(firedb_id)
              ? '#ADD8E6'
              : '',
        }}
        onPress={() => setFavoriteTeam(firedb_id)}
      >
        <ActionsheetItemText>{name}</ActionsheetItemText>
      </ActionsheetItem>
    ),
    [handleClose],
  );

  return (
    <>
      <Box p="$5">
        <VStack space="md" reversed={false}>
          {!loading ? (
            <>
              {/* User section*/}
              <Heading size={'2xl'}>User</Heading>
              {user ? (
                <>
                  <center>
                    <HStack space="md">
                      <Avatar
                        margin="$2"
                        bgColor="$amber600"
                        size="lg"
                        borderRadius="$full"
                      >
                        <AvatarFallbackText>{user.email}</AvatarFallbackText>
                      </Avatar>
                      <VStack>
                        <Heading>{user.email}</Heading>
                        <Text size="sm">FirstName LastName</Text>
                      </VStack>
                    </HStack>
                  </center>
                  <Button
                    onPress={() => {
                      logout();
                    }}
                  >
                    <ButtonText>Sign Out</ButtonText>
                  </Button>

                  <Divider />

                  {/* Wallet section*/}
                  <Heading size={'2xl'}>Wallet</Heading>
                  {user.winnings_management === 'letsbetmx' ? (
                    <>
                      {balance === 0.0 ? (
                        <>
                          <Heading>No Gains</Heading>
                          <SetUpPersonalAddressModal uid={user.uid} />
                        </>
                      ) : (
                        <>
                          <Heading>Balance</Heading>
                          <Heading>100.004</Heading>
                          <Heading>Your Dogecoin is held by letsbetmx</Heading>
                          <Button>Transfer to Personal Address</Button>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Heading>Dogecoin winning sent to:</Heading>
                      <Badge>
                        <BadgeText>{user.deposit_address}</BadgeText>
                      </Badge>
                    </>
                  )}

                  <Divider />

                  {/* Favorite sports, leagues, teams section*/}
                  <Heading size={'2xl'}>Favorites</Heading>
                  <Text>Filter and select your favorite teams</Text>
                  <HStack space="2xl">
                    {/*Basketball*/}
                    <VStack space="md">
                      <Center>
                        <TouchableOpacity
                          onPress={() => handleSportOnClick('basketball')}
                        >
                          <Avatar bgColor="$indigo600">🏀</Avatar>
                        </TouchableOpacity>
                        <VStack>
                          <Heading size="sm">Basketball</Heading>
                        </VStack>
                      </Center>
                    </VStack>
                    {/*Football*/}
                    <VStack space="md">
                      <Center>
                        <TouchableOpacity
                          onPress={() => handleSportOnClick('football')}
                        >
                          <Avatar bgColor="$blue600">🏉</Avatar>
                        </TouchableOpacity>
                        <VStack>
                          <Heading size="sm">Football</Heading>
                        </VStack>
                      </Center>
                    </VStack>
                    {/*Soccer*/}
                    <VStack space="md">
                      <Center>
                        <TouchableOpacity
                          onPress={() => handleSportOnClick('soccer')}
                        >
                          <Avatar bgColor="$yellow600">️⚽️</Avatar>
                        </TouchableOpacity>
                        <VStack>
                          <Heading size="sm">Soccer</Heading>
                        </VStack>
                      </Center>
                    </VStack>
                    {/*Baseball*/}
                    <VStack space="md">
                      <Center>
                        <TouchableOpacity
                          onPress={() => handleSportOnClick('baseball')}
                        >
                          <Avatar bgColor="$pink600">⚾️</Avatar>
                        </TouchableOpacity>
                        <VStack>
                          <Heading size="sm">Baseball</Heading>
                        </VStack>
                      </Center>
                    </VStack>
                  </HStack>
                </>
              ) : (
                <UserNotLoggedIn />
              )}
            </>
          ) : (
            <Loading />
          )}
        </VStack>
      </Box>
      <Actionsheet
        isOpen={showFavoritesSheet}
        onClose={() => setShowFavoritesSheet(!showFavoritesSheet)}
        zIndex={999}
        snapPoints={[50]}
        useRNModal={true}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>
          <ActionsheetSectionHeaderText>
            {selectedSport.sport} Teams
          </ActionsheetSectionHeaderText>
          {selectedSport.teams.length > 0 && !updateLoading && (
            <ActionsheetVirtualizedList
              h="$56"
              data={selectedSport.teams}
              initialNumToRender={5}
              renderItem={({ item }) => (
                <Item
                  name={item.name}
                  logo={item.logo}
                  firedb_id={item.firedb_id}
                />
              )}
              keyExtractor={(item) => item.id}
              getItemCount={getItemCount}
              getItem={getItem}
            />
          )}
        </ActionsheetContent>
      </Actionsheet>
    </>
  );
}
