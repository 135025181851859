import {
  StyleSheet,
  View,
  TouchableOpacity,
  SafeAreaView,
  ScrollView,
  StatusBar,
} from 'react-native';

import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import {
  config,
  GluestackUIProvider,
  Text,
  Box,
  Avatar,
  AvatarImage,
  HStack,
  VStack,
  Badge,
  BadgeText,
  BadgeIcon,
  GlobeIcon,
  Button,
  ButtonIcon,
  AddIcon,
  Divider,
  Center,
  Menu,
  MenuItem,
  ButtonText,
  MenuItemLabel,
  Icon,
} from '@gluestack-ui/themed';
import React, { useEffect, useState } from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Bet from './ui_components/Bet';
import Settings from './ui_components/Settings';

import GamematchList from './ui_components/shared/GamematchList';
import BetList from './ui_components/shared/BetList';
import AuthProvider from './AuthProvider';

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

export default function App() {
  // Set an initializing state whilst Firebase connects
  // const [initializing, setInitializing] = useState(true);

  // const provider = new GoogleAuthProvider();

  {
    /*https://github.com/oblador/react-native-vector-icons#examples*/
    // https://ionic.io/ionicons
  }
  const MainTabScreen = () => {
    return (
      <Tab.Navigator>
        <Tab.Screen
          name="Home"
          component={HomeScreen}
          options={{
            tabBarLabel: '',
            headerShown: false,
            tabBarIcon: ({ color, size }) => (
              <Ionicons name={'home-outline'} size={size} color={color} />
            ),
          }}
        />
        <Tab.Screen
          name="My Bets"
          component={ProfileScreen}
          options={{
            tabBarLabel: '',
            tabBarIcon: ({ color, size }) => (
              <Ionicons name={'cash-outline'} size={size} color={color} />
            ),
          }}
        />
        <Tab.Screen
          name="Settings"
          component={Settings}
          options={{
            tabBarLabel: '',
            tabBarIcon: ({ color, size }) => (
              <Ionicons name={'settings-outline'} size={size} color={color} />
            ),
          }}
        />
      </Tab.Navigator>
    );
  };

  const HomeScreen = ({ navigation }) => {
    return <GamematchList />;
  };
  const ProfileScreen = ({ navigation }) => {
    return <BetList />;
  };
  const ProfileScreen2 = ({ navigation }) => {
    return <Text>This is settings screen</Text>;
  };

  const linking = {
    config: {
      screens: {
        MainTabScreen: '',
        Bet: 'bet/:bet/:deposit?',
      },
    },
  };

  return (
    <GluestackUIProvider colorMode={'light'} config={config.theme}>
      <AuthProvider>
        <NavigationContainer linking={linking}>
          <Stack.Navigator>
            <Stack.Screen
              options={{ headerShown: false }}
              name="MainTabScreen"
              component={MainTabScreen}
            />
            <Stack.Screen
              options={({ navigation, route }) => ({
                headerTitle: () => (
                  <Button
                    variant={'link'}
                    onPress={() => navigation.navigate('MainTabScreen')}
                    action={'positive'}
                    size={'lg'}
                  >
                    LetsBetMX
                  </Button>
                ),
                headerLargeTitle: true,
              })}
              name="Bet"
              component={Bet}
            />
          </Stack.Navigator>
        </NavigationContainer>
      </AuthProvider>
    </GluestackUIProvider>
  );
}
