import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Heading, Spinner, ButtonText } from '@gluestack-ui/themed';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import firebaseUtils from '../../Firebase';
import { AuthContext } from '../../AuthProvider';
import Loading from './Loading';

export default function UserNotLoggedIn(params) {
  const { authLoading, login, logout } = useContext(AuthContext);
  useEffect(() => {
    (async () => {})();
  }, [authLoading]);
  console.log('this is authloading');
  console.log(authLoading);
  console.log('this is authloading');
  return (
    <>
      <Heading>{authLoading} hellooworld</Heading>
      {authLoading ? (
        <Loading />
      ) : (
        // <Loading message={'Google Authentication'} />
        <>
          <Heading>
            <Spinner size="large" /> Login To Access Feature
          </Heading>
          <br />
          <Button onPress={() => login()}>
            <ButtonText> Login With Google Accounts </ButtonText>
          </Button>
        </>
      )}
    </>
  );
}
