import React, { useContext, useEffect } from 'react';
import {
  AddIcon,
  Avatar,
  AvatarFallbackText,
  AvatarImage,
  Badge,
  BadgeText,
  Box,
  Button,
  ButtonIcon,
  ButtonText,
  Center,
  Divider,
  HStack,
  Text,
  VStack,
} from '@gluestack-ui/themed';

import { doc, getDoc } from 'firebase/firestore';
import db from '../Firebase';
import ConfettiCannon from 'react-native-confetti-cannon';

import DepositInfo from './shared/DepositInfo';
import BetFeed from './shared/BetFeed';
import firebaseUtils from '../Firebase';
import { AuthContext } from '../AuthProvider';
import getUtils from './shared/Util';
import Loading from './shared/Loading';

export default function Bet({ navigation, route }) {
  const [bet, setBet] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [deposit, setDeposit] = React.useState(null);
  const [userWon, setUserWon] = React.useState(false);
  const { user, login, logout } = useContext(AuthContext);
  const utils = getUtils();

  useEffect(() => {
    if (!loading) {
      setLoading(true);
    }
    (async () => {
      const docRef = doc(firebaseUtils('db'), 'bets', route.params.bet);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const newBet = docSnap.data();

        setBet(newBet);

        setUserWon(utils.didUserWin(newBet, user));
      }
      if (route.params.deposit !== undefined) {
        const docRef2 = doc(
          firebaseUtils('db'),
          'deposits',
          route.params.deposit,
        );
        const docSnap2 = await getDoc(docRef2);
        if (docSnap2.exists()) {
          const newBet = docSnap2.data();

          setDeposit(newBet);
        }
      }
      setLoading(false);
    })();
  }, [user, route]);

  return (
    <>
      {loading && <Loading />}
      {bet !== null && !loading && (
        <>
          {route.params.deposit === undefined ? (
            <BetFeed bet={bet} showbuttons={true} />
          ) : (
            <>
              {' '}
              <BetFeed bet={bet} showbuttons={false} />
              <br />
              <Divider></Divider>
              <br />
              <DepositInfo deposit={deposit} />
            </>
          )}
          {userWon && <ConfettiCannon count={250} origin={{ x: -10, y: 0 }} />}
        </>
      )}
    </>
  );
}
