import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Avatar,
  AvatarFallbackText,
  AvatarImage,
  Button,
  ButtonText,
  Actionsheet,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetItem,
  ActionsheetItemText,
  ActionsheetSectionHeaderText,
  Icon,
  LockIcon,
  InfoIcon,
  AlertIcon,
  Alert,
  AlertText,
  UnlockIcon,
  Input,
  InputField,
  HStack,
  Box,
  Image,
  VStack,
  ArrowRightIcon,
  Heading,
  ChevronsUpDownIcon,
  Center,
} from '@gluestack-ui/themed';
import { backendApi } from '../../api/backendAPI';
import { useNavigation } from '@react-navigation/native';
import Loading from './Loading';
import UserNotLoggedIn from './UserNotLoggedIn';
import { AuthContext } from '../../AuthProvider';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';

export default function CreateBetModal(params) {
  const [showActionsheet, setShowActionsheet] = React.useState(false);
  const [prediction, setPrediction] = React.useState(null);
  const [betSize, setBetSize] = React.useState('100.00');
  const { user, login, logout } = useContext(AuthContext);

  const handleCloseActionSheet = () => {
    setShowActionsheet(!showActionsheet);
  };

  const handleClose = () => {
    setShowActionsheet(!showActionsheet);
  };
  const triggerFromButton = () => {
    if (user === false) {
      login();
    }
    handleClose();
  };
  const navigation = useNavigation();

  const createBet = (chosen_prediction) => {
    setLoading(true);
    let bet_to_create_entity = {};
    bet_to_create_entity['gamematch_id'] = params.gamematch.firedb_id;
    bet_to_create_entity['user_uid'] = user.uid;
    bet_to_create_entity['amount_to_be_deposited'] = betSize;

    if (chosen_prediction.includes('_or_')) {
      bet_to_create_entity['outcomes'] = chosen_prediction.split('_or_');
    } else {
      bet_to_create_entity['outcomes'] = [chosen_prediction];
    }

    backendApi.make_bet(bet_to_create_entity).then((x) => {
      console.log(x.data);
      navigation.navigate('Bet', {
        bet: x.data.bet_id,
        deposit: x.data.deposit_id,
      });
      handleClose();
      setLoading(false);
    });
  };

  const [gamematch, setGamematch] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    (async () => {
      ///3fa9ff23-ac08-44c0-91e0-4f58f37b8e96
      setGamematch(params.gamematch);

      setLoading(false);
    })();
  }, [user]);
  function generateBetPositions() {
    let open_outcomes = ['home', 'away'];
    if (gamematch.sport === 'soccer') {
      open_outcomes = ['home', 'away', 'tie'];
    }
    const openPositions = open_outcomes.map((x) => {
      if (x === 'home') {
        return (
          <ActionsheetItem key={'home'} onPress={() => setPrediction('home')}>
            <Avatar>
              <AvatarFallbackText></AvatarFallbackText>
              <AvatarImage
                source={{
                  uri: gamematch.teams.home.logo,
                }}
              />
            </Avatar>
            <ActionsheetItemText>
              {gamematch.teams.home.name} Wins
            </ActionsheetItemText>
          </ActionsheetItem>
        );
      }
      if (x === 'away') {
        return (
          <ActionsheetItem key={'away'} onPress={() => setPrediction('away')}>
            <Avatar>
              <AvatarFallbackText></AvatarFallbackText>
              <AvatarImage
                source={{
                  uri: gamematch.teams.away.logo,
                }}
              />
            </Avatar>
            <ActionsheetItemText>
              {gamematch.teams.away.name} Wins
            </ActionsheetItemText>
          </ActionsheetItem>
        );
      }
      if (x === 'tie') {
        return (
          <ActionsheetItem key={'tie'} onPress={() => setPrediction('tie')}>
            <Avatar>
              <AvatarFallbackText></AvatarFallbackText>
              <Icon as={LockIcon} m="$2" w="$4" h="$4" />
            </Avatar>
            <ActionsheetItemText>Tie</ActionsheetItemText>
          </ActionsheetItem>
        );
      }
    });
    if (gamematch.sport === 'soccer') {
      openPositions.push(
        <ActionsheetItem
          key={'home_or_tie'}
          onPress={() => setPrediction('home_or_tie')}
        >
          <Avatar>
            <AvatarFallbackText></AvatarFallbackText>
            <AvatarImage
              source={{
                uri: gamematch.teams.home.logo,
              }}
            />
          </Avatar>
          <ActionsheetItemText>
            {gamematch.teams.home.name} Wins Or Tie
          </ActionsheetItemText>
        </ActionsheetItem>,
      );

      openPositions.push(
        <ActionsheetItem
          key={'away_or_tie'}
          onPress={() => setPrediction('away_or_tie')}
        >
          <Avatar>
            <AvatarFallbackText></AvatarFallbackText>
            <AvatarImage
              source={{
                uri: gamematch.teams.away.logo,
              }}
            />
          </Avatar>
          <ActionsheetItemText>
            {gamematch.teams.away.name} Wins Or Tie
          </ActionsheetItemText>
        </ActionsheetItem>,
      );

      openPositions.push(
        <ActionsheetItem
          key={'away_or_home'}
          onPress={() => setPrediction('away_or_home')}
        >
          <Avatar>
            <AvatarFallbackText></AvatarFallbackText>
            <Icon as={UnlockIcon} m="$2" w="$4" h="$4" />
          </Avatar>
          <ActionsheetItemText>No Tie</ActionsheetItemText>
        </ActionsheetItem>,
      );
    }

    return <>{openPositions}</>;
  }

  return (
    <>
      <Button onPress={triggerFromButton}>
        <ButtonText>Bet</ButtonText>
      </Button>
      {gamematch !== null && (
        <Actionsheet
          isOpen={showActionsheet}
          onClose={handleCloseActionSheet}
          zIndex={999}
          snapPoints={gamematch.sport === 'soccer' ? [77] : [50]}
          useRNModal={true}
        >
          <ActionsheetBackdrop />
          <ActionsheetContent zIndex={999}>
            <ActionsheetDragIndicatorWrapper>
              <ActionsheetDragIndicator />
            </ActionsheetDragIndicatorWrapper>
            {prediction && !loading && (
              <>
                <ActionsheetSectionHeaderText>
                  Bet Size
                </ActionsheetSectionHeaderText>
                <Center>
                  <HStack
                    justifyContent="center"
                    alignItems="center"
                    space="md"
                  >
                    <Box
                      w={50}
                      h="$full"
                      px="$2"
                      borderWidth={1}
                      borderStyle="solid"
                      borderColor="$borderLight300"
                      rounded="$sm"
                    >
                      <Image
                        source={{
                          uri: 'https://cdn.jsdelivr.net/gh/atomiclabs/cryptocurrency-icons@1a63530be6e374711a8554f31b17e4cb92c25fa5/svg/icon/doge.svg',
                        }}
                        flex={1}
                        resizeMode="contain"
                      />
                    </Box>

                    <Input w="$full">
                      <InputField
                        placeholder="DOGE"
                        value={betSize}
                        onChangeText={setBetSize}
                      />
                    </Input>
                  </HStack>
                  <Button
                    isDisabled={isNaN(Number(betSize)) || Number(betSize) <= 1}
                    onPress={() => createBet(prediction)}
                  >
                    <ButtonText>Create Bet</ButtonText>
                  </Button>

                  <ActionsheetSectionHeaderText>
                    OR
                  </ActionsheetSectionHeaderText>
                  <Heading>{betSize} MXN</Heading>

                  <PayPalScriptProvider options={{ 'client-id': 'test' }}>
                    <PayPalButtons style={{ layout: 'horizontal' }} />
                  </PayPalScriptProvider>
                </Center>
              </>
            )}

            {prediction === null && (
              <ActionsheetSectionHeaderText>
                Bet Position
              </ActionsheetSectionHeaderText>
            )}
            {user === false && <UserNotLoggedIn />}

            {loading && <Loading />}
            {gamematch !== null &&
              user !== false &&
              prediction === null &&
              !loading && <>{generateBetPositions()}</>}
            {user !== false && user.winnings_management === 'letsbetmx' && (
              <Alert mx="$2.5" action="info" variant="solid">
                <AlertIcon as={InfoIcon} mr="$3" />
                <AlertText>
                  Dogecoin winnings will be sent to your letsbetmx account. You
                  can configure Dogecoin address in Profile
                </AlertText>
              </Alert>
            )}
            {user !== false && user.winnings_management !== 'letsbetmx' && (
              <Alert mx="$2.5" action="info" variant="solid">
                <AlertIcon as={InfoIcon} mr="$3" />
                <AlertText>
                  Dogecoin winnings will be sent to the Dogecoin address
                  configured in your Profile
                </AlertText>
              </Alert>
            )}
          </ActionsheetContent>
        </Actionsheet>
      )}
    </>
  );
}
