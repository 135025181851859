const util = {
  getIcon: function (gamematch) {
    if (gamematch.sport === 'basketball') {
      return '🏀';
    }
    if (gamematch.sport === 'soccer') {
      return '⚽️';
    }
    if (gamematch.sport === 'football') {
      return '🏉';
    }
    if (gamematch.sport === 'baseball') {
      return '⚾️';
    }
  },
  didUserWin: function (newBet, user) {
    if (user) {
      if (
        user.uid === newBet.home_team_bet_user_uid ||
        user.uid === newBet.away_team_bet_user_uid ||
        user.uid === newBet.tie_bet_user_uid
      ) {
        if (newBet.status === 'resolved') {
          if (
            newBet.resolution === 'home won' &&
            user.uid === newBet.home_team_bet_user_uid
          ) {
            return true;
          } else if (
            newBet.resolution === 'away won' &&
            user.uid === newBet.away_team_bet_user_uid
          ) {
            return true;
          } else if (
            newBet.resolution === 'tie won' &&
            user.uid === newBet.tie_bet_user_uid
          ) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      }
      return false;
    }
    return false;
  },
};

export default function getUtils() {
  return util;
}
