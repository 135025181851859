import React, { useEffect, useState } from 'react';
import {
  AddIcon,
  Avatar,
  AvatarFallbackText,
  AvatarImage,
  Badge,
  BadgeText,
  Box,
  Button,
  ButtonIcon,
  ButtonText,
  Center,
  Divider,
  HStack,
  Text,
  VStack,
} from '@gluestack-ui/themed';
import { Header } from '@gluestack-ui/themed/build/components/Modal/styled-components';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import db from '../../Firebase';
import CreateBetModal from './CreateBetModal';
import Ionicons from 'react-native-vector-icons/Ionicons';
import firebaseUtils from '../../Firebase';
import { StatusBar, StyleSheet } from 'react-native';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigation } from '@react-navigation/native';
import getUtils from './Util';

export default function GamematchCard(params) {
  const [gamematch, setGamematch] = React.useState(null);

  const util = getUtils();
  const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  useEffect(() => {
    (async () => {
      setGamematch(params.gamematch);
    })();
  }, []);

  function determineGameTimeInfo(gamematch) {
    if (gamematch.sport === 'baseball' || gamematch.sport === 'basketball') {
      return (
        days[new Date(gamematch.timestamp * 1000).getDay()] +
        ' ' +
        new Date(gamematch.timestamp * 1000).getDate() +
        '/' +
        (new Date(gamematch.timestamp * 1000).getMonth() + 1) +
        '@' +
        gamematch.time
      );
    }

    if (gamematch.sport === 'soccer') {
      return (
        days[new Date(gamematch.fixture.timestamp * 1000).getDay()] +
        ' ' +
        new Date(gamematch.fixture.timestamp * 1000).getDate() +
        '/' +
        (new Date(gamematch.fixture.timestamp * 1000).getMonth() + 1) +
        '@' +
        new Date(gamematch.fixture.timestamp * 1000).getHours() +
        ':' +
        new Date(gamematch.fixture.timestamp * 1000).getMinutes()
      );
    }

    if (gamematch.sport === 'football') {
      return (
        days[new Date(gamematch.game.date.timestamp * 1000).getDay()] +
        ' ' +
        new Date(gamematch.game.date.timestamp * 1000).getDate() +
        '/' +
        (new Date(gamematch.game.date.timestamp * 1000).getMonth() + 1) +
        '@' +
        gamematch.game.date.time
      );
    }
  }

  return (
    <>
      {gamematch !== null && (
        <Box style={styles.card}>
          <Center>
            <Badge size="md" variant="solid" borderRadius="$none" action="info">
              <BadgeText>
                {util.getIcon(gamematch)} {gamematch.league.name}{' '}
                {determineGameTimeInfo(gamematch)}
              </BadgeText>
            </Badge>
            <HStack>
              <VStack alignItems="center">
                <Avatar>
                  <AvatarFallbackText></AvatarFallbackText>
                  <AvatarImage
                    source={{
                      uri: gamematch.teams.home.logo,
                    }}
                  />
                </Avatar>
                <Text>{gamematch.teams.home.name}</Text>
              </VStack>
              <Divider
                orientation="vertical"
                style={{ margin: 10, width: 2 }}
              />
              <VStack alignItems="center">
                <Avatar>
                  <AvatarFallbackText>SS</AvatarFallbackText>
                  <AvatarImage
                    source={{
                      uri: gamematch.teams.away.logo,
                    }}
                  />
                </Avatar>
                <Text>{gamematch.teams.away.name}</Text>
              </VStack>
            </HStack>
            <br />

            <CreateBetModal gamematch={params.gamematch} />
          </Center>
        </Box>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
});
