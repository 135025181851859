import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonText,
  ModalBackdrop,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalContent,
  Icon,
  CloseIcon,
  Box,
  Heading,
  Modal,
  ModalBody,
} from '@gluestack-ui/themed';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import db from '../../Firebase';
import firebaseUtils from '../../Firebase';
import { TextInput } from 'react-native';
import bs58check from 'bs58check';
import { AuthContext } from '../../AuthProvider';

export default function SetUpPersonalAddressModal(params) {
  const [winningsAddress, setWinningsAddress] = React.useState('');
  const [isAddressValid, setIsAddressValid] = React.useState(false);

  const [showModal, setShowModal] = useState(false);
  const ref = useRef(null);
  const [loading, setLoading] = React.useState(true);
  const { user, login, logout, updateUser } = useContext(AuthContext);

  const validateAddress = (address) => {
    console.log(address);
    try {
      ///Dogecoin addreses must start with a 30
      setIsAddressValid(bs58check.decode(address)[0] == 30);
    } catch (e) {
      setIsAddressValid(false);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <Button onPress={() => setShowModal(true)} ref={ref}>
        <ButtonText>Set Up Personal Wallet</ButtonText>
      </Button>
      <Modal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        finalFocusRef={ref}
      >
        <ModalBackdrop />
        <ModalContent>
          <ModalHeader>
            <Heading size="lg">Engage with Modals</Heading>
            <ModalCloseButton>
              <Icon as={CloseIcon} />
            </ModalCloseButton>
          </ModalHeader>
          <ModalBody>
            <Heading>Enter Dogecoin Address To Deposit Winnings:</Heading>
            <Box>
              <TextInput
                onChange={(e) => {
                  validateAddress(e.target.value);
                  setWinningsAddress(e.target.value);
                }}
              />

              <br />
              {winningsAddress !== '' && !isAddressValid && (
                <Heading>Please enter valid Dogecoin Address</Heading>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="outline"
              size="sm"
              action="secondary"
              mr="$3"
              onPress={() => {
                setShowModal(false);
              }}
            >
              <ButtonText>Cancel</ButtonText>
            </Button>
            <Button
              size="sm"
              action="positive"
              borderWidth="$0"
              disabled={!isAddressValid}
              onPress={() => {
                const default_profile = {
                  winnings_management: 'user',
                  deposit_address: winningsAddress,
                  email: user.email,
                  uid: user.uid,
                };
                updateUser(default_profile, (x) => x);

                setShowModal(false);
              }}
            >
              <ButtonText>Save</ButtonText>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
