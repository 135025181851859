import React, { useState } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  ScrollView,
  StatusBar,
  View,
} from 'react-native';

const FilterChips = ({ onChangeSport }) => {
  const [selectedChip, setSelectedChip] = useState({
    label: 'all',
    value: 'all',
  });

  const chipOptions = [
    {
      label: 'Basketball 🏀',
      value: 'basketball',
    },
    {
      label: 'Soccer ⚽️',
      value: 'soccer',
    },
    {
      label: 'Football 🏉',
      value: 'football',
    },
    {
      label: 'Baseball ⚾️',
      value: 'baseball',
    },
  ];

  const handleChipPress = (chip) => {
    setSelectedChip(chip);
    onChangeSport(chip.value);
  };

  return (
    <View style={{ height: '7%' }}>
      <ScrollView style={styles.scrollView} horizontal={true}>
        {chipOptions.slice(0, 2).map((chip, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => handleChipPress(chip)}
            style={[
              styles.chip,
              selectedChip.value === chip.value && styles.selectedChip,
            ]}
          >
            <Text
              style={selectedChip.value === chip.value && styles.selectedText}
            >
              {chip.label}
            </Text>
          </TouchableOpacity>
        ))}

        {chipOptions.slice(2, 4).map((chip, index) => (
          <TouchableOpacity
            key={index + 2} // Add an offset to the key to avoid conflicts
            onPress={() => handleChipPress(chip)}
            style={[
              styles.chip,
              selectedChip.value === chip.value && styles.selectedChip,
            ]}
          >
            <Text
              style={selectedChip.value === chip.value && styles.selectedText}
            >
              {chip.label}
            </Text>
          </TouchableOpacity>
        ))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10, // Add margin between rows
  },
  chip: {
    backgroundColor: '#EAEAEA',
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 20,
    //flex: 1, // Make chips equally distribute the available space in a row
    marginHorizontal: 5,
    marginBottom: 10, // Add vertical spacing between chips
  },
  selectedChip: {
    backgroundColor: '#007AFF',
  },
  selectedText: {
    color: 'white',
  },
  scrollView: {
    // backgroundColor: 'pink',
    // marginHorizontal: 0,
    width: '100%',
  },
});

export default FilterChips;
