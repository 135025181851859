import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, StyleSheet } from 'react-native';
import {
  Avatar,
  AvatarGroup,
  AvatarFallbackText,
  AvatarImage,
  Badge,
  BadgeText,
  Box,
  Button,
  ButtonText,
  Center,
  HStack,
  Text,
  VStack,
  Heading,
  Toast,
  ToastTitle,
  useToast,
} from '@gluestack-ui/themed';
import { Header } from '@gluestack-ui/themed/build/components/Modal/styled-components';
import { doc, getDoc } from 'firebase/firestore';
import firebaseUtils from '../../Firebase';
import getUtils from './Util';

import CreateCounterBetModal from './CreateCounterBetModal';

import { useNavigation } from '@react-navigation/native';
import { AuthContext } from '../../AuthProvider';
import ConfettiCannon from 'react-native-confetti-cannon';

export default function BetFeed(params) {
  const [gamematch, setGamematch] = React.useState(null);
  const [bet, setBet] = React.useState(null);
  const [homeTeamDeposit, setHomeTeamDeposit] = React.useState(null);
  const [awayTeamDeposit, setAwayTeamDeposit] = React.useState(null);
  const [tieTeamDeposit, setTieTeamDeposit] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const { user, login, logout } = useContext(AuthContext);

  const [isUserInvolved, setIsUserInvolved] = useState(false);
  const [userWon, setUserWon] = useState(null);
  const [userLost, setUserLost] = useState(null);
  const [isThereCounterBet, setIsThereCounterBet] = useState(false);

  const navigation = useNavigation();
  const toast = useToast();

  const utils = getUtils();

  const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  function copyToClipboard() {
    let text = 'https://letsbetmx.com/bet/' + params.bet.uuid;
    (async () => {
      try {
        await navigator.clipboard.writeText(text);
        /* ✅ Copied successfully */

        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="info" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Copied Link To Clipboard</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      } catch (e) {
        /* ❌ Failed to copy (insufficient permissions) */
        toast.show({
          placement: 'top',
          render: ({ id }) => {
            return (
              <Toast nativeId={id} action="error" variant="solid">
                <VStack space="xs">
                  <ToastTitle>Unable To Copy Link</ToastTitle>
                </VStack>
              </Toast>
            );
          },
        });
      }
    })();
  }
  const onShareBet = async () => {
    try {
      const result = await Share.share({
        message: 'LetsBetMX - Lets bet on game ',
        url: 'https://letsbetmx.com/bet/' + params.bet.uuid,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      Alert.alert(error.message);
    }
  };

  useEffect(() => {
    (async () => {
      ///3fa9ff23-ac08-44c0-91e0-4f58f37b8e96

      let newBet = params.bet;

      const gameRef = doc(
        firebaseUtils('db'),
        'gamematch',
        newBet.gamematch_id,
      );
      const gameSnap = await getDoc(gameRef);

      if (gameSnap.exists()) {
        setGamematch(gameSnap.data());
      }

      let homeDeposit = null;
      let awayDeposit = null;
      let tieDeposit = null;

      if (newBet.away_team_bet_deposit_uuid !== null) {
        const depositRef = doc(
          firebaseUtils('db'),
          'deposits',
          newBet.away_team_bet_deposit_uuid,
        );
        const depositSnap = await getDoc(depositRef);
        if (depositSnap.exists()) {
          awayDeposit = depositSnap.data();
          setAwayTeamDeposit(awayDeposit);
        }
      }

      if (newBet.home_team_bet_deposit_uuid !== null) {
        const depositRef = doc(
          firebaseUtils('db'),
          'deposits',
          newBet.home_team_bet_deposit_uuid,
        );
        const depositSnap = await getDoc(depositRef);
        if (depositSnap.exists()) {
          homeDeposit = depositSnap.data();
          setHomeTeamDeposit(homeDeposit);
        }
      }

      if (newBet.tie_bet_deposit_uuid !== null) {
        const depositRef = doc(
          firebaseUtils('db'),
          'deposits',
          newBet.tie_bet_deposit_uuid,
        );
        const depositSnap = await getDoc(depositRef);
        if (depositSnap.exists()) {
          tieDeposit = depositSnap.data();
          setTieTeamDeposit(tieDeposit);
        }
      }

      if (user !== false) {
        if (
          user.uid === newBet.home_team_bet_user_uid ||
          user.uid === newBet.away_team_bet_user_uid ||
          user.uid === newBet.tie_bet_user_uid
        ) {
          setIsUserInvolved(true);

          if (newBet.status === 'resolved') {
            if (
              newBet.resolution === 'home won' &&
              user.uid === newBet.home_team_bet_user_uid
            ) {
              setUserWon(true);
            } else if (
              newBet.resolution === 'away won' &&
              user.uid === newBet.away_team_bet_user_uid
            ) {
              setUserWon(true);
            } else if (
              newBet.resolution === 'tie won' &&
              user.uid === newBet.tie_bet_user_uid
            ) {
              setUserWon(true);
            } else if (newBet.resolution !== 'nobody won') {
              setUserLost(true);
            }
          }

          if (
            newBet.home_team_bet_user_uid &&
            user.uid !== newBet.home_team_bet_user_uid
          ) {
            setIsThereCounterBet(true);
          } else if (
            newBet.away_team_bet_user_uid &&
            user.uid !== newBet.away_team_bet_user_uid
          ) {
            setIsThereCounterBet(true);
          } else if (
            newBet.tie_bet_user_uid &&
            user.uid !== newBet.tie_bet_user_uid
          ) {
            setIsThereCounterBet(true);
          }
        }
      } else {
        setIsUserInvolved(false);
      }

      setBet(newBet);

      setLoading(false);
    })();
  }, [params]);

  function generateGameSummary() {
    return (
      <Header>
        {utils.getIcon(gamematch)}
        {gamematch.league.name}{' '}
        {days[new Date(gamematch.timestamp * 1000).getDay()] +
          ' ' +
          new Date(gamematch.timestamp * 1000).getDate() +
          '/' +
          (new Date(gamematch.timestamp * 1000).getMonth() + 1) +
          '@' +
          gamematch.time}
      </Header>
    );
  }
  function generatePossibleWinnings() {
    if (bet.bet_size === undefined) {
      return <></>;
    }
    if (bet.open_outcomes.length === 0) {
      return <></>;
    }

    let total = 0;
    let counted_deposits = [];
    let amount_to_be_deposited = bet.bet_size;

    if (homeTeamDeposit !== null) {
      total = amount_to_be_deposited + total;
    }

    if (awayTeamDeposit !== null) {
      total = amount_to_be_deposited + total;
    }

    if (tieTeamDeposit !== null && bet.sport === 'soccer') {
      total = tieTeamDeposit.balance + total;
    }

    if (userWon) {
      if (user.winnings_management === 'letsbetmx') {
        return (
          <Center>
            <Heading size={'xs'}>
              {total} DOGE Deposited to your letsbetMX Acct
            </Heading>
          </Center>
        );
      } else {
        return (
          <Center>
            <Heading size={'xs'}>
              {total} DOGE Deposited to your personal Acct
            </Heading>
          </Center>
        );
      }
    }
    if (userLost) {
      return <></>;
    }

    return (
      <Center>
        <Heading size={'xs'}>Prize {total} DOGE</Heading>
      </Center>
    );
  }

  function generateTotal() {
    if (bet.bet_size === undefined) {
      return <></>;
    }

    let total = 0;
    let counted_deposits = [];
    let amount_to_be_deposited = bet.bet_size;

    if (homeTeamDeposit !== null) {
      total = amount_to_be_deposited + total;
    }
    if (awayTeamDeposit !== null) {
      total = amount_to_be_deposited + total;
    }

    if (tieTeamDeposit !== null && bet.sport === 'soccer') {
      total = amount_to_be_deposited + total;
    }

    if (bet.open_outcomes.length === 0) {
      return (
        <Center>
          <Heading size={'xs'}>Prize {total} DOGE</Heading>
        </Center>
      );
    }

    return (
      <Center>
        <Heading size={'xs'}>
          Bet {amount_to_be_deposited} DOGE >> Win {total} DOGE
        </Heading>
      </Center>
    );
  }
  function generateBadge() {
    if (bet.status === 'resolved') {
      ///TODO CHekc if user won or lost
      if (isUserInvolved) {
        if (userWon) {
          return (
            <Badge
              size="md"
              variant="solid"
              borderRadius="$none"
              action="success"
            >
              <BadgeText>Bet Resolved - You WON!!</BadgeText>
            </Badge>
          );
        }
        if (userLost) {
          return (
            <Badge
              size="md"
              variant="solid"
              borderRadius="$none"
              action="error"
            >
              <BadgeText>Bet Resolved - You LOST!</BadgeText>
            </Badge>
          );
        }
      }

      return (
        <Badge size="md" variant="solid" borderRadius="$none" action="info">
          <BadgeText>Bet Resolved - {bet.resolution}</BadgeText>
        </Badge>
      );
    }
    if (bet.confirmed_outcomes.length === bet.all_posible_outcomes.length) {
      if (bet.status === 'to_be_resolved') {
        return (
          <Badge size="md" variant="solid" borderRadius="$none" action="info">
            <BadgeText>Waiting On Bet Resolution</BadgeText>
          </Badge>
        );
      }
    } else if (bet.status === 'expired_initial_deposit') {
      return (
        <Badge size="md" variant="solid" borderRadius="$none" action="error">
          <BadgeText>Initial Deposit Expired</BadgeText>
        </Badge>
      );
    } else {
      if (
        bet.confirmed_outcomes.length + bet.pending_outcomes.length ===
        bet.all_posible_outcomes.length
      ) {
        return (
          <Badge
            size="md"
            variant="solid"
            borderRadius="$none"
            action="warning"
          >
            <BadgeText>WAITING ON COUNTER BET PAYMENT CONFIRMATION</BadgeText>
          </Badge>
        );
      } else {
        if (
          bet.creator !== undefined &&
          user !== false &&
          bet.creator === user.uid
        ) {
          if (!isThereCounterBet) {
            return (
              <Badge
                size="md"
                variant="solid"
                borderRadius="$none"
                action="success"
              >
                <BadgeText>OPEN BET - WAITING ON COUNTER BET!!</BadgeText>
              </Badge>
            );
          } else {
            return <></>;
          }
        }
        return (
          <Badge
            size="md"
            variant="solid"
            borderRadius="$none"
            action="success"
          >
            <BadgeText>OPEN BET - PLACE COUNTER BET!!</BadgeText>
          </Badge>
        );
      }
    }
  }

  function generateShareBet() {
    return (
      <Button
        onPress={() => {
          copyToClipboard();
        }}
      >
        <ButtonText>Share</ButtonText>
      </Button>
    );
  }

  function generateGoToBetAndDepositPage(bet_id2, deposit_id2, position) {
    return (
      <Button
        onPress={() => {
          navigation.navigate('Bet', {
            bet: bet_id2,
            deposit: deposit_id2,
          });
        }}
      >
        <ButtonText>View {position}</ButtonText>
      </Button>
    );
  }
  function generateBetSummaryUserInvolved() {
    let outcomes_text = {};
    outcomes_text['home'] = gamematch.teams.home.name + ' Wins';
    outcomes_text['away'] = gamematch.teams.away.name + ' Wins';
    outcomes_text['tie'] = 'Tie No Winner';

    let user_outcomes = [];
    if (bet.home_team_bet_user_uid === user.uid) {
      user_outcomes.push('home');
    }
    if (bet.away_team_bet_user_uid === user.uid) {
      user_outcomes.push('away');
    }
    if (gamematch.sport === 'soccer' && bet.tie_bet_user_uid === user.uid) {
      user_outcomes.push('tie');
    }
    let non_user_confirmed_outcomes = [];
    for (let i = 0; i < bet.confirmed_outcomes.length; i++) {
      if (!user_outcomes.includes(bet.confirmed_outcomes[i])) {
        non_user_confirmed_outcomes.push(bet.confirmed_outcomes[i]);
      }
    }
    let non_user_pending_outcomes = [];
    for (let i = 0; i < bet.pending_outcomes.length; i++) {
      if (!user_outcomes.includes(bet.pending_outcomes[i])) {
        non_user_pending_outcomes.push(bet.pending_outcomes[i]);
      }
    }

    let result = (
      <>
        <Center>
          <Badge size="lg" variant="solid" borderRadius="$none" action="info">
            <BadgeText>
              {utils.getIcon(gamematch)} {gamematch.league.name}{' '}
              {determineGameTimeInfo(gamematch)}
            </BadgeText>
          </Badge>
          <AvatarGroup>
            <Avatar size={'sm'}>
              <AvatarFallbackText></AvatarFallbackText>
              <AvatarImage
                source={{
                  uri: gamematch.teams.home.logo,
                }}
              />
            </Avatar>
            <Avatar size={'sm'}>
              <AvatarFallbackText></AvatarFallbackText>
              <AvatarImage
                source={{
                  uri: gamematch.teams.away.logo,
                }}
              />
            </Avatar>
          </AvatarGroup>

          {user_outcomes.length === 1 ? (
            <Heading size={'xs'}>Your Outcome:</Heading>
          ) : (
            <Heading size={'xs'}>Your Outcomes:</Heading>
          )}
          {user_outcomes.map((outcome, index) => {
            return (
              <Text key={index}>
                {outcomes_text[outcome]}
                {bet.pending_outcomes.includes(outcome) && ' (Pending)'}
              </Text>
            );
          })}

          {non_user_confirmed_outcomes.length > 0 && (
            <>
              {non_user_confirmed_outcomes.length === 1 ? (
                <Heading size={'xs'}>Counter Outcome:</Heading>
              ) : (
                <Heading size={'xs'}>Counter Outcomes:</Heading>
              )}
              {non_user_confirmed_outcomes.map((outcome, index) => {
                return <Text key={index}>{outcomes_text[outcome]}</Text>;
              })}
            </>
          )}

          {bet.open_outcomes.length > 0 && (
            <>
              {bet.open_outcomes.length === 1 ? (
                <Heading size={'xs'}>Open Outcome:</Heading>
              ) : (
                <Heading size={'xs'}>Open Outcomes:</Heading>
              )}
              {bet.open_outcomes.map((outcome, index) => {
                return <Text key={index}>{outcomes_text[outcome]}</Text>;
              })}
            </>
          )}

          {non_user_pending_outcomes.length > 0 && (
            <>
              {non_user_pending_outcomes.length === 1 ? (
                <Heading size={'xs'}>Pending Outcome:</Heading>
              ) : (
                <Heading size={'xs'}>Pending Outcomes:</Heading>
              )}
              {non_user_pending_outcomes.map((outcome, index) => {
                return <Text key={index}>{outcomes_text[outcome]}</Text>;
              })}
            </>
          )}
        </Center>
      </>
    );

    return result;
  }
  function generateBetSummary() {
    let outcomes_text = {};
    outcomes_text['home'] = gamematch.teams.home.name + ' Wins';
    outcomes_text['away'] = gamematch.teams.away.name + ' Wins';
    outcomes_text['tie'] = 'Tie No Winner';

    let result = (
      <>
        <Center>
          <Badge size="lg" variant="solid" borderRadius="$none" action="info">
            <BadgeText>
              {utils.getIcon(gamematch)} {gamematch.league.name}{' '}
              {determineGameTimeInfo(gamematch)}
            </BadgeText>
          </Badge>
          <AvatarGroup>
            <Avatar size={'sm'}>
              <AvatarFallbackText></AvatarFallbackText>
              <AvatarImage
                source={{
                  uri: gamematch.teams.home.logo,
                }}
              />
            </Avatar>
            <Avatar size={'sm'}>
              <AvatarFallbackText></AvatarFallbackText>
              <AvatarImage
                source={{
                  uri: gamematch.teams.away.logo,
                }}
              />
            </Avatar>
          </AvatarGroup>

          {bet.confirmed_outcomes.length === 1 ? (
            <Heading size={'xs'}>Outcome:</Heading>
          ) : (
            <Heading size={'xs'}>Outcomes:</Heading>
          )}
          {bet.confirmed_outcomes.map((outcome, index) => {
            return <Text key={index}>{outcomes_text[outcome]}</Text>;
          })}
          {bet.pending_outcomes.map((outcome, index) => {
            return <Text key={index}>{outcomes_text[outcome]} (Pending)</Text>;
          })}
        </Center>
      </>
    );

    return result;
  }

  function showCounterBetModal() {
    if (isUserInvolved) {
      if (bet.open_outcomes.length > 0 && isThereCounterBet) {
        return (
          <Center>
            <HStack space="md">
              {params.showbuttons &&
                user.uid === bet.home_team_bet_user_uid &&
                generateGoToBetAndDepositPage(
                  params.bet.uuid,
                  bet.home_team_bet_deposit_uuid,
                  'Home',
                )}
              {params.showbuttons &&
                user.uid === bet.away_team_bet_user_uid &&
                generateGoToBetAndDepositPage(
                  params.bet.uuid,
                  bet.away_team_bet_deposit_uuid,
                  'Away',
                )}
              {params.showbuttons &&
                user.uid === bet.tie_bet_user_uid &&
                generateGoToBetAndDepositPage(
                  params.bet.uuid,
                  bet.tie_bet_deposit_uuid,
                  'Tie',
                )}
              {generateShareBet()}
              <CreateCounterBetModal gamematch={gamematch} bet={params.bet} />
            </HStack>
          </Center>
        );
      } else {
        return (
          <Center>
            <HStack space="md">
              {params.showbuttons &&
                user.uid === bet.home_team_bet_user_uid &&
                generateGoToBetAndDepositPage(
                  params.bet.uuid,
                  bet.home_team_bet_deposit_uuid,
                  'Home',
                )}
              {params.showbuttons &&
                user.uid === bet.away_team_bet_user_uid &&
                generateGoToBetAndDepositPage(
                  params.bet.uuid,
                  bet.away_team_bet_deposit_uuid,
                  'Away',
                )}
              {params.showbuttons &&
                user.uid === bet.tie_bet_user_uid &&
                generateGoToBetAndDepositPage(
                  params.bet.uuid,
                  bet.tie_bet_deposit_uuid,
                  'Tie',
                )}
              {generateShareBet()}
            </HStack>
          </Center>
        );
      }
    }
    if (
      (gamematch.sport === 'soccer' &&
        (homeTeamDeposit === null ||
          awayTeamDeposit === null ||
          tieTeamDeposit === null)) ||
      (gamematch.sport !== 'soccer' &&
        (homeTeamDeposit === null || awayTeamDeposit === null))
    ) {
      return (
        <>
          <Center>
            <Heading>Think otherwise?</Heading>
            <CreateCounterBetModal gamematch={gamematch} bet={params.bet} />
          </Center>
        </>
      );
    }
    return <></>;
  }

  function determineGameTimeInfo(gamematch) {
    if (gamematch.sport === 'baseball' || gamematch.sport === 'basketball') {
      return (
        days[new Date(gamematch.timestamp * 1000).getDay()] +
        ' ' +
        new Date(gamematch.timestamp * 1000).getDate() +
        '/' +
        (new Date(gamematch.timestamp * 1000).getMonth() + 1) +
        '@' +
        gamematch.time
      );
    }

    if (gamematch.sport === 'soccer') {
      return (
        days[new Date(gamematch.fixture.timestamp * 1000).getDay()] +
        ' ' +
        new Date(gamematch.fixture.timestamp * 1000).getDate() +
        '/' +
        (new Date(gamematch.fixture.timestamp * 1000).getMonth() + 1) +
        '@' +
        new Date(gamematch.fixture.timestamp * 1000).getHours() +
        ':' +
        new Date(gamematch.fixture.timestamp * 1000).getMinutes()
      );
    }

    if (gamematch.sport === 'football') {
      return (
        days[new Date(gamematch.game.date.timestamp * 1000).getDay()] +
        ' ' +
        new Date(gamematch.game.date.timestamp * 1000).getDate() +
        '/' +
        (new Date(gamematch.game.date.timestamp * 1000).getMonth() + 1) +
        '@' +
        gamematch.game.date.time
      );
    }
  }

  return (
    <>
      {!loading && gamematch !== null && bet !== null && (
        <Box style={styles.card} justifyContent="center">
          <VStack space={'xs'} bg="$grey100">
            {!isUserInvolved
              ? generateBetSummary()
              : generateBetSummaryUserInvolved()}

            {isUserInvolved && generateBadge()}
            {showCounterBetModal()}
            {!isUserInvolved ? generateTotal() : generatePossibleWinnings()}
          </VStack>
        </Box>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  card: {
    // flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingTop: StatusBar.currentHeight,
    marginRight: 20,
    marginLeft: 20,
    marginTop: 20,
    paddingBottom: 20,
    paddingTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#fff',
  },
});
