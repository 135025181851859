// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// Initialize Firebase

const firebaseConfig = {
  apiKey: 'AIzaSyDSSPEb3YRuPe3Z-6WfIY1ZNFiiFFQy7xg',
  authDomain: 'letsbet-pr.firebaseapp.com',
  projectId: 'letsbet-pr',
  storageBucket: 'letsbet-pr.appspot.com',
  messagingSenderId: '175353461032',
  appId: '1:175353461032:web:35452faf7d9373b954381d',
  measurementId: 'G-07K7LPWYZ2',
  databaseURL: 'https://letsbet-pr-default-rtdb.firebaseio.com/',
};

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
const db_1 = getFirestore(app);
const auth2 = getAuth(app);
export default function firebaseUtils(toolName) {
  if (toolName == 'db') {
    return db_1;
  }

  if (toolName == 'auth') {
    return auth2;
  }
}
