import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  AddIcon,
  Avatar,
  AvatarFallbackText,
  AvatarImage,
  Button,
  ButtonText,
  Actionsheet,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetItem,
  ActionsheetItemText,
  ActionsheetIcon,
  ActionsheetSectionHeaderText,
  Radio,
  RadioGroup,
  RadioLabel,
  RadioIndicator,
  InfoIcon,
  AlertIcon,
  Alert,
  AlertText,
  UnlockIcon,
  Heading,
  LockIcon,
  Icon,
} from '@gluestack-ui/themed';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import db from '../../Firebase';
import { backendApi } from '../../api/backendAPI';
import { useNavigation } from '@react-navigation/native';
import firebaseUtils from '../../Firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import Loading from './Loading';
import UserNotLoggedIn from './UserNotLoggedIn';
import { AuthContext } from '../../AuthProvider';

export default function CreateCounterBetModal(params) {
  const [showActionsheet, setShowActionsheet] = React.useState(false);
  const { user, login, logout } = useContext(AuthContext);

  const triggerFromButton = () => {
    if (user === false) {
      login();
    } else if (bet.open_outcomes.length === 1) {
      //We are authed adn there is only one outcome left, make the bet :)
      createBet(bet.open_outcomes[0]);
    }
    handleClose();
  };

  const handleClose = () => setShowActionsheet(!showActionsheet);

  const navigation = useNavigation();

  const createBet = (chosen_prediction) => {
    setLoading(true);
    let bet_to_create_entity = {};
    bet_to_create_entity['user_uid'] = user.uid;
    bet_to_create_entity['gamematch_id'] = params.gamematch.firedb_id;
    bet_to_create_entity['bet_id'] = params.bet.uuid;

    if (chosen_prediction.includes('_or_')) {
      bet_to_create_entity['outcomes'] = chosen_prediction.split('_or_');
    } else {
      bet_to_create_entity['outcomes'] = [chosen_prediction];
    }

    backendApi.make_counter_bet(bet_to_create_entity).then((x) => {
      console.log(x.data);
      navigation.navigate('Bet', {
        bet: x.data.bet_id,
        deposit: x.data.deposit_id,
      });
      handleClose();
      setLoading(false);
    });
  };

  const [gamematch, setGamematch] = React.useState(null);
  const [bet, setBet] = React.useState(null);

  const [loading, setLoading] = React.useState(false);

  function generateCounterBetTitle() {
    let outcome = bet.open_outcomes[0];
    if (outcome === 'home') {
      return 'Bet on ' + gamematch.teams.home.name;
    }
    if (outcome === 'away') {
      return 'Bet on ' + gamematch.teams.away.name;
    }
    if (outcome === 'tie') {
      return 'Bet on Tie';
    }
  }
  function generateOpenBetPositions() {
    const openPositions = bet.open_outcomes.map((x) => {
      if (x === 'home') {
        return (
          <ActionsheetItem key={'home'} onPress={() => createBet('home')}>
            <Avatar>
              <AvatarFallbackText></AvatarFallbackText>
              <AvatarImage
                source={{
                  uri: gamematch.teams.home.logo,
                }}
              />
            </Avatar>
            <ActionsheetItemText>
              {gamematch.teams.home.name} Wins
            </ActionsheetItemText>
          </ActionsheetItem>
        );
      }
      if (x === 'away') {
        return (
          <ActionsheetItem key={'away'} onPress={() => createBet('away')}>
            <Avatar>
              <AvatarFallbackText></AvatarFallbackText>
              <AvatarImage
                source={{
                  uri: gamematch.teams.away.logo,
                }}
              />
            </Avatar>
            <ActionsheetItemText>
              {gamematch.teams.away.name} Wins
            </ActionsheetItemText>
          </ActionsheetItem>
        );
      }
      if (x === 'tie') {
        return (
          <ActionsheetItem key={'tie'} onPress={() => createBet('tie')}>
            <Avatar>
              <AvatarFallbackText></AvatarFallbackText>
            </Avatar>
            <ActionsheetItemText>Tie</ActionsheetItemText>
          </ActionsheetItem>
        );
      }
    });

    if (gamematch.sport === 'soccer') {
      if (
        bet.open_outcomes.includes('home') &&
        bet.open_outcomes.includes('tie')
      ) {
        openPositions.push(
          <ActionsheetItem
            key={'home_or_tie'}
            onPress={() => createBet('home_or_tie')}
          >
            <Avatar>
              <AvatarFallbackText></AvatarFallbackText>
              <AvatarImage
                source={{
                  uri: gamematch.teams.home.logo,
                }}
              />
            </Avatar>
            <ActionsheetItemText>
              {gamematch.teams.home.name} Wins Or Tie
            </ActionsheetItemText>
          </ActionsheetItem>,
        );
      }

      if (
        bet.open_outcomes.includes('away') &&
        bet.open_outcomes.includes('tie')
      ) {
        openPositions.push(
          <ActionsheetItem
            key={'away_or_tie'}
            onPress={() => createBet('away_or_tie')}
          >
            <Avatar>
              <AvatarFallbackText></AvatarFallbackText>
              <AvatarImage
                source={{
                  uri: gamematch.teams.away.logo,
                }}
              />
            </Avatar>
            <ActionsheetItemText>
              {gamematch.teams.away.name} Wins Or Tie
            </ActionsheetItemText>
          </ActionsheetItem>,
        );
      }

      if (
        bet.open_outcomes.includes('away') &&
        bet.open_outcomes.includes('home')
      ) {
        openPositions.push(
          <ActionsheetItem
            key={'away_or_home'}
            onPress={() => createBet('away_or_home')}
          >
            <Avatar>
              <AvatarFallbackText></AvatarFallbackText>
              <Icon as={UnlockIcon} m="$2" w="$4" h="$4" />
            </Avatar>
            <ActionsheetItemText>No Tie</ActionsheetItemText>
          </ActionsheetItem>,
        );
      }
    }

    return <>{openPositions}</>;
  }
  useEffect(() => {
    (async () => {
      ///3fa9ff23-ac08-44c0-91e0-4f58f37b8e96
      setGamematch(params.gamematch);

      setBet(params.bet);

      setLoading(false);
    })();
  }, [user]);

  return (
    <>
      <Button onPress={triggerFromButton}>
        <ButtonText>
          {bet !== null && gamematch !== null
            ? generateCounterBetTitle()
            : 'Counter Bet'}
        </ButtonText>
      </Button>

      {gamematch !== null && (
        <Actionsheet
          isOpen={showActionsheet}
          onClose={handleClose}
          zIndex={999}
          snapPoints={gamematch.sport === 'soccer' ? [70] : [50]}
        >
          <ActionsheetBackdrop />
          <ActionsheetContent zIndex={999}>
            <ActionsheetDragIndicatorWrapper>
              <ActionsheetDragIndicator />
            </ActionsheetDragIndicatorWrapper>
            {(user === false || user === null) && <UserNotLoggedIn />}

            {user !== false && user.winnings_management === 'letsbetmx' && (
              <Alert mx="$2.5" action="info" variant="solid">
                <AlertIcon as={InfoIcon} mr="$3" />
                <AlertText>
                  Dogecoin winnings will be sent to your letsbetmx account. You
                  can configure Dogecoin address in Profile
                </AlertText>
              </Alert>
            )}
            {user !== false && user.winnings_management !== 'letsbetmx' && (
              <Alert mx="$2.5" action="info" variant="solid">
                <AlertIcon as={InfoIcon} mr="$3" />
                <AlertText>
                  Dogecoin winnings will be sent to the Dogecoin address
                  configured in your Profile
                </AlertText>
              </Alert>
            )}
            <ActionsheetSectionHeaderText>
              Bet Position
            </ActionsheetSectionHeaderText>
            {bet !== null && gamematch !== null && user !== false && (
              <>{!loading ? generateOpenBetPositions() : <Loading />}</>
            )}
          </ActionsheetContent>
        </Actionsheet>
      )}
    </>
  );
}
