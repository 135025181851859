import axios, { AxiosResponse } from 'axios';

const BASE_URL =
  'https://us-central1-letsbet-pr.cloudfunctions.net/backend_app';
// const BASE_URL = 'http://127.0.0.1:5000';

// checkUser checks if the user has an account with us or not
async function make_bet(new_bet) {
  try {
    const json = JSON.stringify(new_bet);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(`${BASE_URL}/make_bet`, json, {
      headers: {
        //'google-token-id': tokenId,
        //'Content-Type': 'application/json',
        'Content-Type': 'text/plain',
        //'Access-Control-Allow-Credentials': true,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

async function make_counter_bet(new_bet) {
  try {
    const json = JSON.stringify(new_bet);
    console.log('make play ');
    console.log(json);
    console.log('make play ');

    const response = await axios.post(`${BASE_URL}/make_counter_bet`, json, {
      headers: {
        //'google-token-id': tokenId,
        //'Content-Type': 'application/json',
        'Content-Type': 'text/plain',
        //'Access-Control-Allow-Credentials': true,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
  }
}

export const backendApi = {
  make_bet,
  make_counter_bet,
};
