import React, { useEffect, useRef, useState } from 'react';
import { Heading, Spinner } from '@gluestack-ui/themed';

export default function Loading(params) {
  if (params.message === undefined) {
    return (
      <Heading>
        <Spinner size="large" /> Loading {params.message}....
      </Heading>
    );
  }
  return (
    <Heading>
      <Spinner size="large" /> Loading....
    </Heading>
  );
}
