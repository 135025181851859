import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Box,
  Select,
  SelectTrigger,
  SelectInput,
  SelectIcon,
  Icon,
  SelectPortal,
  SelectBackdrop,
  SelectContent,
  SelectDragIndicatorWrapper,
  SelectDragIndicator,
  SelectItem,
  ChevronDownIcon,
  Center,
} from '@gluestack-ui/themed';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import {
  doc,
  getDoc,
  collection,
  query,
  and,
  where,
  limit,
  or,
  getDocs,
} from 'firebase/firestore';

import firebaseUtils from '../../Firebase';

import Loading from './Loading';
import UserNotLoggedIn from './UserNotLoggedIn';
import BetFeed from './BetFeed';
import { AuthContext } from '../../AuthProvider';

export default function BetList() {
  const [filter, setFilter] = React.useState([
    'resolved',
    'expired_initial_deposit',
    'to_be_resolved',
    'open_for_counter_bets',
    'pending_counter_bets',
    'waiting_on_payment',
  ]);
  const [bets, setBets] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const { user, login, logout } = useContext(AuthContext);

  useEffect(() => {
    console.log('Thats the filter');
    console.log(filter);
    console.log('Thats the filter');
    (async () => {
      setLoading(true);
      const new_gamematches = [];

      if (user) {
        let q = null;
        const gamesmatchRef = collection(firebaseUtils('db'), 'bets');

        q = query(
          gamesmatchRef,
          and(
            or(
              where('home_team_bet_user_uid', '==', user.uid),
              where('away_team_bet_user_uid', '==', user.uid),
              where('tie_bet_user_uid', '==', user.uid),
            ),
          ),
          limit(200),
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc1) => {
          new_gamematches.push(doc1.data());
        });

        setBets(new_gamematches);
      }

      setLoading(false);
    })();
  }, [user, filter]);

  return (
    <>
      {user === false ? (
        <Box>
          <Center>
            <UserNotLoggedIn />
          </Center>
        </Box>
      ) : (
        <>
          {user && !loading ? (
            <SafeAreaView style={styles.container}>
              <Select
                onValueChange={(e) => {
                  if (e === 'pending') {
                    setFilter([
                      'to_be_resolved',
                      'open_for_counter_bets',
                      'pending_counter_bets',
                    ]);
                  }
                  if (e === 'expired') {
                    setFilter(['expired_initial_deposit']);
                  }
                  if (e === 'resolved') {
                    setFilter(['resolved']);
                  }
                }}
              >
                <SelectTrigger variant="rounded" size="lg">
                  <SelectInput placeholder="Filter Bets" />
                  <SelectIcon mr="$3">
                    <Icon as={ChevronDownIcon} />
                  </SelectIcon>
                </SelectTrigger>
                <SelectPortal>
                  <SelectBackdrop />
                  <SelectContent>
                    <SelectDragIndicatorWrapper>
                      <SelectDragIndicator />
                    </SelectDragIndicatorWrapper>
                    <SelectItem label="Pending" value="pending" />
                    <SelectItem label="Resolved" value="resolved" />
                    <SelectItem label="Expired" value="expired" />
                  </SelectContent>
                </SelectPortal>
              </Select>
              <ScrollView style={styles.scrollView}>
                {bets.length > 0 && (
                  <>
                    {bets
                      .filter((x) => {
                        return filter.includes(x.status);
                      })
                      .map((x) => (
                        <BetFeed key={x['uuid']} bet={x} showbuttons={true} />
                      ))}
                  </>
                )}
              </ScrollView>
            </SafeAreaView>
          ) : (
            <Loading />
          )}
        </>
      )}
    </>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#f1f2f5',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: StatusBar.currentHeight,
  },
  scrollView: {
    // backgroundColor: 'pink',
    // marginHorizontal: 0,
    width: '100%',
  },
  buttonContainer: {
    backgroundColor: '#4630eb',
    borderRadius: 4,
    padding: 12,
    marginVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    color: '#ffffff',
    fontSize: 16,
  },
});
