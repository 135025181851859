import React, { useEffect, useRef, useState } from 'react';
import {
  AddIcon,
  Avatar,
  AvatarFallbackText,
  AvatarImage,
  Button,
  ButtonText,
  Actionsheet,
  ActionsheetBackdrop,
  ActionsheetContent,
  ActionsheetDragIndicatorWrapper,
  ActionsheetDragIndicator,
  ActionsheetItem,
  ActionsheetItemText,
  ActionsheetIcon,
  ActionsheetSectionHeaderText,
  Radio,
  RadioGroup,
  RadioLabel,
  RadioIndicator,
  InfoIcon,
  AlertIcon,
  Alert,
  AlertText,
  UnlockIcon,
  Box,
} from '@gluestack-ui/themed';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import db from '../../Firebase';
import { backendApi } from '../../api/backendAPI';
import { useNavigation } from '@react-navigation/native';
import firebaseUtils from '../../Firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import QRCode from 'react-qr-code';

export default function ViewQRCodeModal(params) {
  const [showActionsheet, setShowActionsheet] = React.useState(false);

  const triggerFromButton = () => {
    handleClose();
  };

  const handleClose = () => setShowActionsheet(!showActionsheet);

  useEffect(() => {
    (async () => {
      ///3fa9ff23-ac08-44c0-91e0-4f58f37b8e96
    })();
  }, []);

  return (
    <>
      <Button onPress={triggerFromButton}>
        <ButtonText>QR Code</ButtonText>
      </Button>

      <Actionsheet
        isOpen={showActionsheet}
        onClose={handleClose}
        zIndex={999}
        snapPoints={[60]}
      >
        <ActionsheetBackdrop />
        <ActionsheetContent zIndex={999}>
          <ActionsheetDragIndicatorWrapper>
            <ActionsheetDragIndicator />
          </ActionsheetDragIndicatorWrapper>

          <ActionsheetSectionHeaderText>QR Code</ActionsheetSectionHeaderText>

          <Box>
            <QRCode
              size={200}
              style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
              value={params.address}
              viewBox={`0 0 200 200`}
            />
          </Box>
        </ActionsheetContent>
      </Actionsheet>
    </>
  );
}
