import { createContext, useContext, useEffect, useState } from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import firebaseUtils from './Firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

export const AuthContext = createContext({ authLoading: true });

export default function AuthProvider(props) {
  const provider = new GoogleAuthProvider();

  const updateUser = (obj, doneLoading) => {
    setDoc(doc(firebaseUtils('db'), 'users', obj.uid), obj).then(() => {
      setUser(obj);
      doneLoading(false);
    });
  };
  const login = () => {
    signInWithPopup(firebaseUtils('auth'), provider)
      // .then((result) => {
      //   // The signed-in user info.
      //   const user2 = result.user;
      //
      //   (async () => {
      //     const docRef = doc(firebaseUtils('db'), 'users', user2.uid);
      //     const docSnap = await getDoc(docRef);
      //     if (!docSnap.exists()) {
      //       const default_profile = {
      //         winnings_management: 'letsbetmx',
      //         deposit_address: '',
      //         email: user2.email,
      //         uid: user2.uid,
      //       };
      //       setUser(default_profile);
      //       await setDoc(
      //         doc(firebaseUtils('db'), 'users', user2.uid),
      //         default_profile,
      //       );
      //     } else {
      //       setUser(docSnap.data());
      //     }
      //   })();
      // })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(error);
        console.log('error');
        if (authLoading) {
          setAuthLoading(false);
          console.log('setting auth loading to false');
        } else {
          console.log('auth loading is already false');
        }
        // ...
      });
  };
  const logout = () => {
    setUser(false);
    firebaseUtils('auth').signOut();
  };

  const [user, setUser] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  useEffect(() => {
    firebaseUtils('auth').onAuthStateChanged((loggedInUser) => {
      (async () => {
        console.log('heyyyy this was called');
        console.log('heyyyy this was called');
        console.log('heyyyy this was called');
        console.log('heyyyy this was called');
        console.log(loggedInUser);
        console.log('heyyyy this was called');

        if (loggedInUser) {
          const docRef = doc(firebaseUtils('db'), 'users', loggedInUser.uid);
          const docSnap = await getDoc(docRef);
          if (!docSnap.exists()) {
            const default_profile = {
              winnings_management: 'letsbetmx',
              deposit_address: '',
              email: loggedInUser.email,
              uid: loggedInUser.uid,
            };
            setUser(default_profile);
            await setDoc(
              doc(firebaseUtils('db'), 'users', loggedInUser.uid),
              default_profile,
            );
          } else {
            setUser(docSnap.data());
          }
        }
      })().then(() => setAuthLoading(false));
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{ authLoading, user, login, logout, updateUser }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
